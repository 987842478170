<template>
  <v-card>
    <v-card-title class="justify-center">
      <!--
      <form>
        <v-text-field v-model="filtro" label="Buscar:" required></v-text-field>
        <v-btn class="mr-4" @click="buscar()">
          Buscar
        </v-btn>
      </form>
      -->
      <v-row>
        <!--
        <v-col cols="12" class="text-center">
          <v-btn color="primary" @click="newItem()">Nuevo Registro</v-btn>
        </v-col>
        -->
        <v-col cols="12" class="text-center">
          <v-btn color="primary" dark class="mb-2" @click="downloadExport()">
            <v-icon left>
              mdi-download
            </v-icon>
            Descargar
          </v-btn>
        </v-col>
      </v-row>
      <RemisoriaComisionAgregar
        v-if="dialog"
        @saved="fetchData()"
        @dialogChange="dialogChange"
        :dialog="dialog"
        :dato="dato"
      />
    </v-card-title>
    <v-data-table :headers="headers" :items="datos" hide-default-footer>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"
          >mdi-information</v-icon
        >
      </template>
    </v-data-table>

    <v-pagination
      v-model="page"
      :length="pages"
      v-on:input="fetchData()"
    ></v-pagination>
  </v-card>
</template>

<script>
import axios from "axios";
import RemisoriaComisionAgregar from "@/components/RemisoriaComisionAgregar.vue";
import RemisoriaService from "@/api/remisoria/RemisoriaService";
import moment from "moment";

export default {
  components: {
    RemisoriaComisionAgregar
  },
  data() {
    return {
      dato: {},
      dialog: false,
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "id", align: "start", value: "id" },
        { text: "# Remisoria", value: "numero" },
        { text: "# Poliza", value: "numeroPoliza" },
        { text: "Asegurado", value: "nombreAseguradoPrincipal" },
        { text: "updatedBy", value: "updatedBy.getFullNameDisplay" },
        { text: "Acción", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        observacion: "",
        primaPagada: 0.0,
        primaAnual: 0.0,
        porcentajeAgenciaNuevo: 0.0,
        porcentajeAgenciaRenovacion: 0.0,
        porcentajeAgenteNuevo: 0.0,
        porcentajeAgenteRenovacion: 0.0
      };
      this.dato = newDto;
    },
    dialogChange(value) {
      this.dialog = value;
    },
    editItem(item) {
      this.dato = { ...item };
      this.dato.fecha = new Date(this.dato.fecha);
      this.dato.fechaEmision = new Date(this.dato.fechaEmision);
      this.dato.fechaEmisionPoliza = new Date(this.dato.fechaEmisionPoliza);
      this.dato.agente = this.dato.agente.id;
      this.dato.plan = this.dato.plan.id;
      this.dato.compañia = this.dato.compañia.id;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    buscar() {
      this.search = this.filtro;
      //this.fetchData();
    },
    fetchData() {
      console.log("llamando api");
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url +
            "/remisoria-base/paginado?page=" +
            this.page +
            "&limit=10" +
            "&search=" +
            this.search
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    },
    downloadExport() {
      RemisoriaService.getRemisoriasExcel()
        .then(response => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          const today = new Date();
          moment.locale("es");
          link.setAttribute(
            "download",
            `remisoria-_${moment(today).format("YYYY-MMMM-DD")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          console.log("no se pudo generar el documento");
        });
    }

    //nuevo
  },
  created() {
    this.fetchData();
  }
};
</script>
