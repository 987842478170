import httpClient from "../http-client";

const ENDPOINT = "/remisoria-base";

class RemisoriaService {
  getRemisoriasExcel() {
    return httpClient.get(`${ENDPOINT}/excel`, {
      responseType: "blob"
    });
  }

  createRemisoria(dto: any) {
    return httpClient.post(ENDPOINT, dto);
  }

  cerrarRemisorias(dto: any) {
    return httpClient.post(`${ENDPOINT}/cierre-anual`, dto);
  }
}
export default new RemisoriaService();
