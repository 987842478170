<template>
  <v-dialog v-bind:value="dialog" persistent max-width="600px">
    <v-form>
      <v-card>
        <v-card-title>
          <span class="headline">{{
            dtoSave.id ? "Editar" : "Crear nuevo"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="% Agencia Nuevo"
                  v-model.number="dtoSave.porcentajeAgenciaNuevo"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="% Agencia Renovación"
                  v-model.number="dtoSave.porcentajeAgenciaRenovacion"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="% Agente Nuevo"
                  v-model.number="dtoSave.porcentajeAgenteNuevo"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="% Agente Renovación"
                  v-model.number="dtoSave.porcentajeAgenteRenovacion"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>* campos requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "axios";
//import DateTimePicker from "@/components/shared/DateTimePicker.vue";
import RemisoriaService from "@/api/remisoria/RemisoriaService";
import PlanService from "@/api/plan/PlanService";
//import "selectize/dist/css/selectize.css";
//import VSelectize from "@isneezy/vue-selectize";
export default {
  components: {
    //DateTimePicker
    //VSelectize
  },
  props: ["dialog", "dato"],
  data() {
    return {
      datetime: new Date(),
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      dtoSave: {
        observacion: "",
        primaPagada: 0.0,
        primaAnual: 0.0,
        porcentajeAgenciaNuevo: 0.0,
        porcentajeAgenciaRenovacion: 0.0,
        porcentajeAgenteNuevo: 0.0,
        porcentajeAgenteRenovacion: 0.0,
        fecha: new Date(),
        fechaEmision: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        fechaEmisionPoliza: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      //selects

      agentes: [],
      planes: []
    };
  },
  methods: {
    dialogChange(value) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    buscar() {
      this.search = this.filtro;
      this.fetchData();
    },
    fetchData() {
      console.log("llamando api");
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url +
            "/datos-base/paginado?page=" +
            this.page +
            "&limit=10" +
            "&search=" +
            this.search
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    },
    loadAgentes() {
      axios.get(this.url + "/agentes-base").then(response => {
        this.agentes = response.data || [];
        this.agentes.sort(function(a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (b.nombre > a.nombre) {
            return -1;
          }
          return 0;
        });
        //this.pages = response.data?.meta?.totalPages || 0;
      });
    },
    loadPlanes() {
      const params = new URLSearchParams({
        status: "1"
      }).toString();
      PlanService.getAll(params)
        .then(response => {
          this.planes = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Planes",
            text: "No se pudo obtener"
          });
        });
    },
    save() {
      const dto = { ...this.dtoSave };
      dto.fecha = dto.fecha.toISOString();
      dto.fechaEmision = dto.fechaEmision.toISOString();
      dto.fechaEmisionPoliza = dto.fechaEmisionPoliza.toISOString();
      RemisoriaService.createRemisoria(dto)
        .then(() => {
          //
          this.$emit("saved", "someValue");
          this.close();
        })
        .catch(error => {
          console.log(error);
        });
      /*
      const data = JSON.parse(JSON.stringify(this.dtoSave));
      console.log(data);
      axios
        .post(this.url + "/datos-base", data)
        .then(response => {
          console.log(response);
          this.dialog = false;
        })
        .catch(error => {
          console.log(error);
        });
        */
    }
    //nuevo
  },
  created() {
    //this.fetchData();
    this.dtoSave = {};
    this.dtoSave = { ...this.dato };
    this.loadAgentes();
    this.loadPlanes();
  }
};
</script>
